<template>
    <div class="container">
        <auto-table :DataList="DataList" :option="option" :total="total">
            <el-table-column label="状态" prop="status">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1" style="color: green">已生效</span>
          <span v-else-if="scope.row.status == 0" style="color: red">未生效</span>
        </template>
      </el-table-column>
            <el-table-column label="操作" width="300">
                <template v-slot="{ row }">
                    <el-button type="primary" size="small" @click="editCourse(row)">设置课程</el-button>
                    <el-button type="success" size="small" v-if="row.status == 1" @click="update(row, 1)">上架</el-button>
                    <el-button type="danger" size="small" v-if="row.status == 1" @click="update(row, 2)">下架</el-button>
                </template>
            </el-table-column>
        </auto-table>
    </div>
</template>

<script>
export default {

    data() {
        return {
            DataList: [],
            option: [{ name: '名称', value: 'name' },
            { name: '价格', value: 'price' },
            { name: '折扣价', value: 'discount_price' },
            { name: '有效期(月)', value: 'duration' },
            { name: '库存', value: 'inventory' },
            ],
            total: 0
        };
    },

    mounted() {
        this.getList()
    },

    methods: {
        editCourse(row) {
            this.$router.push({ name: 'talentCardCourse', query: { member_id: row.id } })
        },
        update(row, status) {
            this.$axios({
                url: 'user/member/card/update',
                method: 'post',
                data: {
                    id: row.id,
                    status
                }
            }).then(res => {
                this.$message('成功')
                this.getList()
            })
        },
        getList() {
            this.$axios({
                url: 'user/member/card/queryManagerListPage', params: {
                    type: 2,
                }
            }).then(res => {
                this.DataList = res.data.data.rows
                this.total = res.data.data.total
            })
        }
    },
};
</script>

<style lang="scss" scoped></style>